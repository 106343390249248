import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Activity1 from "../images/activity_1.png";
import Activity2 from "../images/activity_2.png";
import Activity3 from "../images/activity_3.png";
import Activity4 from "../images/activity_4.png";

const files = [
  {
    title: "Recycle Used Cooking Oil and Earn Cashback",
    size: "By Arus Oil",
    source: Activity1,
  },
  {
    title: "Attend Financial Literacy Workshop and Start Saving",
    size: "By Pod",
    source: Activity3,
  },
  {
    title: "Understand Gig Economy and Start Earning",
    size: "By Go Get",
    source: Activity2,
  },
  {
    title: "Learn Insurance and Get Coverage",
    size: "By Senang Insurance",
    source: Activity4,
  },
];

interface Props {
  history: any;
}

export default class SelectedActivity extends Component<Props> {
  componentDidMount = async () => {
    const authItem = await window.localStorage.getItem("arusoil-auth");
    if (authItem === "Demo Authenticated") {
      this.setState({ authenticated: true });
    } else {
      this.props.history.push("/auth");
    }
  };

  renderActivities = () => {
    return (
      <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
        {files.map((file) => (
          <li key={file.source} className="relative">
            <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-arusgreen-500 overflow-hidden">
              <Link to="/activity/arus">
                <img
                  src={file.source}
                  alt=""
                  className="object-cover cursor-pointer group-hover:opacity-75"
                />
              </Link>
            </div>
            <p className="mt-2 block text-lg font-medium text-arusgreen-500 pointer-events-none">
              {file.title}
            </p>
            <p className="block text-md font-medium text-gray-500 pointer-events-none">
              {file.size}
            </p>
          </li>
        ))}
      </ul>
    );
  };
  render() {
    return (
      <div className="min-h-screen bg-gray-100">
        <Header history={this.props.history} />
        <div className="py-10">
          <header>
            <div className="max-w-7xl flex mx-auto px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold leading-tight text-gray-900">
                Jadualkan Koleksi Untuk Kitar Semula UCO
              </h1>
            </div>
          </header>
          <main>
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="px-4 py-4 sm:px-0">
                <div className="space-y-6">
                  <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                      <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          APIs Pihak Ketiga
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Dengan menyerahkan, anda bersetuju bahawa anda
                          berkongsi anda data kepada pihak ketiga kami,
                          khususnya - Minyak Arus
                        </p>
                      </div>
                      <div className="mt-5 md:mt-0 md:col-span-2">
                        <div className="space-y-6">
                          <div>
                            <label
                              htmlFor="about"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Hari Pilihan Untuk Koleksi
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                placeholder="Weekend"
                                autoComplete="given-name"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor="about"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Masa Pilihan Untuk Koleksi
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                placeholder="4pm-6pm"
                                autoComplete="given-name"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor="about"
                              className="block text-sm font-medium text-gray-700"
                            >
                              No Telefon
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                placeholder="+60123456789"
                                autoComplete="given-name"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor="about"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Alamat
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                placeholder="Residence Address"
                                autoComplete="given-name"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Muat naik imej UCO yang dikumpul
                            </label>
                            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                              <div className="space-y-1 text-center">
                                <svg
                                  className="mx-auto h-12 w-12 text-gray-400"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 48 48"
                                  aria-hidden="true"
                                >
                                  <path
                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                <div className="flex text-sm text-gray-600">
                                  <label
                                    htmlFor="file-upload"
                                    className="relative cursor-pointer bg-white rounded-md font-medium text-arusgreen-600 hover:text-arusgreen-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-arusgreen-500"
                                  >
                                    <span>Muat naik file</span>
                                    <input
                                      id="file-upload"
                                      name="file-upload"
                                      type="file"
                                      className="sr-only"
                                    />
                                  </label>
                                  <p className="pl-1">atau seret dan lepas</p>
                                </div>
                                <p className="text-xs text-gray-500">
                                  PNG, JPG, GIF up to 10MB
                                </p>
                              </div>
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor="about"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Anggaran Berat UCO dalam KG
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                placeholder="0kg"
                                autoComplete="given-name"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>
                          </div>

                          <div>
                            <button
                              type="submit"
                              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-arusgreen-500 hover:bg-arusgreen-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-arusgreen-500"
                            >
                              Jadualkan Koleksi
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}
