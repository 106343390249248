import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Activities from "../pages/Activities";
import Auth from "../pages/Auth";
import Home from "../pages/Home";
import SelectedActivity from "../pages/SelectedActivity";

export default class AppNavigator extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/auth" component={Auth} />
          <Route exact path="/" component={Home} />
          <Route exact path="/activities" component={Activities} />
          <Route exact path="/activity/arus" component={SelectedActivity} />
        </Switch>
      </BrowserRouter>
    );
  }
}
