import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Activity1 from "../images/activity_1.png";
import Activity2 from "../images/activity_2.png";
import Activity3 from "../images/activity_3.png";
import Activity4 from "../images/activity_4.png";

const files = [
  {
    title: "Kitar Semula Minyak Masak Terpakai dan Dapatkan Pulangan Tunai",
    size: "Daripada Arus Oil",
    source: Activity1,
  },
  {
    title: "Gotong Royong Bersama-sama",
    size: "Daripada KRT",
    source: Activity3,
  },
  {
    title: "Aktiviti Hijau Ketiga",
    size: "Daripada -",
    source: Activity2,
  },
  {
    title: "Aktiviti Hijau Ke-empat",
    size: "Daripada -",
    source: Activity4,
  },
];

interface Props {
  history: any;
}

export default class Activities extends Component<Props> {
  componentDidMount = async () => {
    const authItem = await window.localStorage.getItem("arusoil-auth");
    if (authItem === "Demo Authenticated") {
      this.setState({ authenticated: true });
    } else {
      this.props.history.push("/auth");
    }
  };

  renderActivities = () => {
    return (
      <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
        {files.map((file) => (
          <li key={file.source} className="relative">
            <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-arusgreen-500 overflow-hidden">
              <Link to="/activity/arus">
                <img
                  src={file.source}
                  alt=""
                  className="object-cover cursor-pointer group-hover:opacity-75"
                />
              </Link>
            </div>
            <p className="mt-2 block text-lg font-medium text-arusgreen-600 pointer-events-none">
              {file.title}
            </p>
            <p className="block text-md font-medium text-gray-500 pointer-events-none">
              {file.size}
            </p>
          </li>
        ))}
      </ul>
    );
  };
  render() {
    return (
      <div className="min-h-screen bg-gray-100">
        <Header history={this.props.history} />
        <div className="py-10">
          <header>
            <div className="max-w-7xl flex mx-auto px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold leading-tight text-gray-900">
                Aktiviti Disyorkan Untuk Anda
              </h1>
            </div>
          </header>
          <main>
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="px-4 py-4 sm:px-0">
                <div className="mt-5">{this.renderActivities()}</div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}
