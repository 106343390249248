import {
  AcademicCapIcon,
  CashIcon,
  CheckCircleIcon,
  ChevronDoubleUpIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import Header from "../components/Header";

const applications = [
  {
    applicant: {
      name: "James Kar",
      email: "Bintang Pertama",
      imageUrl:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    stage: "Mengumpul 40kg minyak masak terpakai pada bulan ini",
  },
  {
    applicant: {
      name: "Melissa Yeoh",
      email: "Bintang Kedua",
      imageUrl:
        "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    stage: "Mengumpul 30kg minyak masak terpakai pada bulan ini",
  },
  {
    applicant: {
      name: "Iqbal Han",
      email: "Bintang Ketiga",
      imageUrl:
        "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    stage: "Mengumpul 20kg minyak masak terpakai pada bulan ini",
  },
];

interface Props {
  history: any;
}

export default class Home extends Component<Props> {
  componentDidMount = async () => {
    const authItem = await window.localStorage.getItem("arusoil-auth");
    if (authItem === "Demo Authenticated") {
      this.setState({ authenticated: true });
    } else {
      this.props.history.push("/auth");
    }
  };

  renderRanks = () => {
    return (
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <ul className="divide-y divide-gray-200">
          {applications.map((application) => (
            <li key={application.applicant.email}>
              <div className="block hover:bg-gray-50">
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <div className="min-w-0 flex-1 flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="h-12 w-12 rounded-full"
                        src={application.applicant.imageUrl}
                        alt=""
                      />
                    </div>
                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                      <div>
                        <p className="text-sm font-medium text-arusgreen-500 truncate">
                          {application.applicant.name}
                        </p>
                        <p className="mt-2 flex items-center text-sm text-gray-500">
                          <ChevronDoubleUpIcon
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="truncate">
                            {application.applicant.email}
                          </span>
                        </p>
                      </div>
                      <div className="hidden md:block">
                        <div>
                          <p className="mt-2 flex items-center text-sm text-gray-500">
                            <CheckCircleIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                              aria-hidden="true"
                            />
                            {application.stage}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  renderGoals = () => {
    const goalList = [
      {
        color: "bg-green-400",
        colorString: "#22C55E",
        icon: <TrashIcon className="h-5 w-5 text-white" />,
        progress: 70,
        title: "Kitar semula 100kg UCO bulan ini",
        label: "Minyak Masak Terpakai",
      },
      {
        color: "bg-blue-400",
        colorString: "#3B82F6",
        icon: <CashIcon className="h-5 w-5 text-white" />,
        progress: 40,
        title: "Mengurang 600kg Karbon bulan ini",
        label: "Karbon(CO2) Dikurang",
      },
      {
        color: "bg-indigo-400",
        colorString: "#6366F1",
        icon: <AcademicCapIcon className="h-5 w-5 text-white" />,
        progress: 30,
        title: "Selesaikan 5 Matlamat Kumpulan",
        label: "Matlamat Pihak Ketiga",
      },
    ];

    const goalListView: any = [];
    goalList.map((eachGoal) => {
      const barType = {
        series: [eachGoal.progress],
        options: {
          chart: {
            height: 350,
          },
          colors: [eachGoal.colorString],
          plotOptions: {
            radialBar: {
              hollow: {
                size: "70%",
              },
            },
          },
          labels: [eachGoal.label],
        },
      };

      goalListView.push(
        <div
          className="bg-white overflow-hidden shadow rounded-lg"
          style={{ height: "fit-content" }}
        >
          <div className="p-5">
            <div className="flex">
              <div className="flex-shrink-0">
                <span className={`flex p-2 mt-1 rounded-lg ${eachGoal.color}`}>
                  {eachGoal.icon}
                </span>
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-md font-medium text-gray-500 truncate">
                    {eachGoal.title}
                  </dt>
                  <dd>
                    <ReactApexChart
                      options={barType.options}
                      series={barType.series}
                      type="radialBar"
                      height={350}
                    />
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      );

      return null;
    });

    return goalListView;
  };

  render() {
    return (
      <div className="min-h-screen bg-gray-100">
        <Header history={this.props.history} />
        <div className="py-10">
          <header>
            <div className="max-w-7xl flex mx-auto px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold leading-tight text-gray-900">
                Matlamat Komuniti
              </h1>
            </div>
          </header>
          <main>
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="px-4 py-4 sm:px-0">
                <div className="mt-5">
                  <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-3">
                    {this.renderGoals()}
                  </div>
                </div>

                <div className="mt-12">
                  <h2 className="text-lg mb-6 leading-6 font-medium text-gray-900">
                    Kedudukan Minyak Masak Terpakai Kitar Semula
                  </h2>
                  {this.renderRanks()}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}
