import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import DashboardLogo from "../images/logo.png";
import ProfileImage from "../images/profile.png";

const navigation = [
  { name: "Papan pemuka", href: "/" },
  { name: "Aktiviti", href: "/activities" },
];
const userNavigation = [{ name: "Log Keluar", action: "logout", href: "#" }];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface Props {
  history: any;
}

export default class Header extends Component<Props> {
  handleSignOut = async () => {
    await window.localStorage.clear();
    this.props.history.push("/auth");
  };

  render() {
    return (
      <Disclosure as="nav" className="bg-white shadow-sm">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 md:px-6 lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="flex-shrink-0 flex items-center">
                    <Link to="/">
                      <img
                        className="block lg:hidden h-10 w-auto"
                        src={DashboardLogo}
                        alt="Arus Oil Logo"
                      />
                      <img
                        className="hidden lg:block h-10 w-auto"
                        src={DashboardLogo}
                        alt="Arus Oil Logo"
                      />
                    </Link>
                  </div>
                  <div className="hidden md:-my-px md:ml-6 md:flex md:space-x-8">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                        )}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="hidden md:ml-6 md:flex md:space-x-8">
                  <div className="hidden md:ml-3 md:flex md:space-x-8 md:items-center">
                    <Menu as="div" className="ml-3 z-50 relative">
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button className="bg-white flex text-sm rounded-full items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-arusgreen-500 focus:text-arusgreen-500">
                              <span className="sr-only">Open user menu</span>
                              <img
                                className="h-8 w-8 rounded-full"
                                src={ProfileImage}
                                alt=""
                              />
                              <ChevronDownIcon
                                className="block h-5 w-5"
                                aria-hidden="true"
                              />
                            </Menu.Button>
                          </div>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              {userNavigation.map((item) => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => {
                                    if (item.action) {
                                      return (
                                        <span
                                          className={classNames(
                                            active ? "bg-gray-100" : "",
                                            "cursor-pointer block px-4 py-2 text-sm text-gray-700"
                                          )}
                                          onClick={this.handleSignOut}
                                        >
                                          {item.name}
                                        </span>
                                      );
                                    } else {
                                      return (
                                        <Link
                                          to={item.href}
                                          className={classNames(
                                            active ? "bg-gray-100" : "",
                                            "block px-4 py-2 text-sm text-gray-700"
                                          )}
                                        >
                                          {item.name}
                                        </Link>
                                      );
                                    }
                                  }}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </div>
                </div>
                <div className="-mr-2 flex items-center md:hidden">
                  <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-arusgreen-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <Link
                    to={item.href}
                    key={item.name}
                    className={classNames(
                      "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
                      "block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                    )}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="pt-4 pb-3 border-t border-gray-200">
                <div className="flex items-center px-4">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={ProfileImage}
                      alt=""
                    />
                  </div>
                </div>
                <div className="mt-3 space-y-1">
                  {userNavigation.map((item) => {
                    if (item.action) {
                      return (
                        <span
                          className="cursor-pointer block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                          onClick={this.handleSignOut}
                        >
                          {item.name}
                        </span>
                      );
                    } else {
                      return (
                        <Link
                          to={item.href}
                          key={item.name}
                          className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                        >
                          {item.name}
                        </Link>
                      );
                    }
                  })}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  }
}
